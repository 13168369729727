<template>
    <section class="comparison">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-6 col-xl-3 mb-4 mb-xl-0">
                    <div class="card h-100"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <div class="card-body">
                            <div class="card-title text-center d-flex flex-column justify-content-center py-4">
                                <h4 class="font-weight-bold mb-0">
                                    節食
                                </h4>
                            </div>
                            <div class="card-text">
                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        舒適度
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        脂肪
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        不能針對難減部位
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        身形
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        無助線條修形
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        需時
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        需長時間堅持
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        過程
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        不適
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-xl-3 mb-4 mb-xl-0">
                    <div class="card h-100"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <div class="card-body">
                            <div class="card-title text-center d-flex flex-column justify-content-center py-4">
                                <h4 class="font-weight-bold mb-0">
                                    運動
                                </h4>
                            </div>
                            <div class="card-text">
                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        舒適度
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        脂肪
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        難以擊退部份頑固脂肪
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        身形
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        鍛鍊出肌肉形
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        需時
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        需時長 成效慢
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        過程
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        疲累
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-xl-3 mb-4 mb-xl-0">
                    <div class="card h-100"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <div class="card-body">
                            <div class="card-title text-center d-flex flex-column justify-content-center py-4">
                                <h4 class="font-weight-bold mb-0">
                                    一般療程
                                </h4>
                            </div>
                            <div class="card-text">
                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        舒適度
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        脂肪
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        不能凋亡脂肪細胞
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        身形
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        能修形
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        需時
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        容易反彈
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        過程
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        痛楚
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-xl-3 mb-4 mb-xl-0">
                    <div class="card h-100 featured"
                         data-aos="fade-up"
                         data-aos-duration="1000">
                        <div class="card-body">
                            <div class="card-title text-center d-flex flex-column justify-content-center py-4">
                                <h4 class="font-weight-bold mb-0">
                                    超·頻消脂修身療程
                                </h4>
                            </div>
                            <div class="card-text">
                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        舒適度
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                        <font-awesome-icon icon="star"></font-awesome-icon>
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        脂肪
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        <span class="mr-1">
                                            凋亡脂肪細胞
                                        </span>
                                        <a class="font-weight-bolder text-decoration-none"
                                           style="font-size: 110%; "
                                           href="#mono-sculpting-disclaimer">
                                            *
                                        </a>
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        身形
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        修形達
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-xl-3 col-6 header text-center px-0 my-3">
                                        需時
                                    </div>
                                    <div class="col-xl-9 col-6 my-3">
                                        效果持久
                                    </div>
                                </div>

                                <div class="row m-0">
                                    <div class="col-6 col-xl-3 header text-center px-0 my-3">
                                        過程
                                    </div>
                                    <div class="col-6 col-xl-9 my-3">
                                        無痛無創 微溫感
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "MonoSculptingComparison"
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .comparison {
        background-color: $background-color;
        color: rgba(90, 90, 90, 1);

        .card {


            &.featured {
                color: rgba(86, 212, 244, 1);
                outline: solid 8px rgba(86, 212, 244, 1);
            }
        }
    }
</style>
